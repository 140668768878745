import React, { useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { Transition } from "react-transition-group";
import { RemoveRounded } from "@mui/icons-material";
import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import CalenderEventDetails from "./CalenderEventDetails";
import { CANCEL_JOBINST_MUTATION } from "../utils/mutations";
import { payPerHour } from "../utils/global-consts";
import { beautifyTime } from "../utils/util-functions";

const hrHeightPx = 32;

const EDIT_JOBINSTANCE_MUTATION = gql`
  mutation EditJobInstance(
    $id: ID!
    $start_time: Float
    $end_time: Float
    $job: ID
    $pay: Float
    $length: Float
    $description: String
  ) {
    editJobInstance(
      jobInstance: $id
      start_time: $start_time
      end_time: $end_time
      job: $job
      pay: $pay
      length: $length
      description: $description
    ) {
      id
    }
  }
`;

const EventWrapper = styled.div(
  ({
    isOvernight,
    status,
    startFloat,
    endFloat,
    dayDiffToFirstDay,
    active,
    dragging,
    editing,
    nextDay,
  }) => [
    tw`absolute z-40 bg-red-300 border-l-2 border-red-500 rounded-md shadow-lg text-sm px-2 py-2 transition duration-100 hover:(cursor-pointer shadow-xl) overflow-hidden`,
    isOvernight && tw`rounded-b-none`,
    status === 2 && tw`bg-yellow-200 border-yellow-400`,
    status === 3 && tw`bg-green-300 border-green-500`,
    `top: ${(startFloat / 24) * 100}%;
  bottom: ${((24 - endFloat) / 24) * 100}%;
  left: ${(dayDiffToFirstDay * 100) / 7}%;
  width: ${100 / 7}%;
  `,
    active && tw`shadow-lg`,
    dragging && tw`cursor-move shadow-2xl`,
    editing && tw`hover:cursor-move`,
    nextDay && tw`rounded-t-none`,
  ]
);
const ResizeBarWrapper = styled.div(({ pos }) => [
  tw`flex absolute z-50 inset-x-0 justify-center cursor-ns-resize opacity-80`,
  pos === "top" && tw`-top-2`,
  pos === "bottom" && tw`-bottom-2`,
]);

const CalendarEvent = ({
  calEvent,
  showDetailsToggle,
  setShowDetailsToggle,
  setShowDetailsGlobal,
  firstDay,
  updateCurrJobs,
}) => {
  const [eventThis, setEventThis] = useState({ ...calEvent });
  let status = 1;
  if (calEvent.num_req_workers === calEvent.workers.length) {
    status = 3;
  } else if (calEvent.workers.length > 0) {
    status = 2;
  }
  const eventStartObj = new Date(parseInt(eventThis.start_time, 10));
  const eventEndObj = new Date(
    parseInt(eventThis.start_time, 10) + eventThis.length * 3600000
  );
  const [editJobInstance] = useMutation(EDIT_JOBINSTANCE_MUTATION);
  const [cancelJobInstance] = useMutation(CANCEL_JOBINST_MUTATION);

  const startFloat = eventStartObj.getHours() + eventStartObj.getMinutes() / 60;
  const endFloat = startFloat + eventThis.length;
  let isOvernight = false;
  if (endFloat > 24) isOvernight = true;

  const firstDayMN = firstDay.getTime();
  const startObjClone = new Date(eventStartObj.getTime());
  startObjClone.setHours(0, 0, 0, 0);
  const eventMN = startObjClone.getTime();
  const dayDiffToFirstDay = (eventMN - firstDayMN) / 86400000;

  const [showDetailsThis, setShowDetailsThis] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    setShowDetailsThis(false);
    setIsEditing(false);
    setEventThis({ ...calEvent });
  }, [showDetailsToggle]);

  const handleMouseDownOnResizeBar = (e, styleKey) => {
    e.preventDefault();
    e.stopPropagation();

    const calWrapperElem = document.getElementById("cal-wrapper");
    const calWrapperHeight = calWrapperElem.getBoundingClientRect().height;
    const calWrapperOffT = calWrapperElem.offsetTop;
    const scrHeight = calWrapperElem.scrollHeight;

    const originalMouseY = e.pageY;
    let newMouseY = originalMouseY;
    const origStart = startFloat;
    let startAfterOut = origStart;
    let startNewPrecise = origStart;
    const origEnd = endFloat;
    let endAfterOut = origEnd;
    const origStartDate = eventStartObj;

    const resizeEvent = ev => {
      ev.stopPropagation();

      const startDateClone = new Date(origStartDate.getTime());

      let startNew;
      let startHoursNew;
      let startMinNew;
      let lengthNewPrecise;
      let lengthNew;

      const currScrollT = calWrapperElem.scrollTop;

      if (styleKey === "top") {
        if (ev.pageY < calWrapperOffT + 16) {
          startHoursNew = 0;
          startMinNew = 0;
          lengthNew = origEnd;

          if (currScrollT > 0) {
            calWrapperElem.scrollTop = 0;
            newMouseY = calWrapperOffT + 16;
            startAfterOut = 0.5;
          }
        } else {
          startNewPrecise = startAfterOut + (ev.pageY - newMouseY) / hrHeightPx;

          if (startNewPrecise > origEnd - 1.1) {
            startHoursNew = Math.floor(origEnd - 1);
            startMinNew = ((origEnd - 1) % startHoursNew) * 60;
            lengthNew = 1;
          } else {
            startNew = Math.round(startNewPrecise * 4) / 4;
            lengthNew = origEnd - startNew;
            startHoursNew = Math.floor(startNew);
            if (startHoursNew === 0) {
              startMinNew = startNew * 60;
            } else {
              startMinNew = (startNew % startHoursNew) * 60;
            }
          }
        }

        startDateClone.setHours(startHoursNew, startMinNew);
        setEventThis({
          ...eventThis,
          start_time: startDateClone.getTime(),
          length: lengthNew,
        });
      } else {
        if (ev.pageY > calWrapperOffT + calWrapperHeight - 16) {
          lengthNew = 24 - origStart;

          if (currScrollT < scrHeight - calWrapperHeight) {
            calWrapperElem.scrollTop = scrHeight;
            newMouseY = calWrapperOffT + calWrapperHeight - 16;
            endAfterOut = 23.5;
          }
        } else {
          lengthNewPrecise =
            endAfterOut + (ev.pageY - newMouseY) / hrHeightPx - origStart;
          if (lengthNewPrecise < 1.25) {
            lengthNew = 1;
          } else {
            lengthNew = Math.round(lengthNewPrecise * 4) / 4;
          }
        }

        setEventThis({
          ...eventThis,
          length: lengthNew,
        });
      }

      setIsEditing(true);
    };

    const stopResize = eve => {
      eve.stopPropagation();
      window.removeEventListener("mousemove", resizeEvent);
    };

    window.addEventListener("mousemove", resizeEvent);
    window.addEventListener("mouseup", stopResize);
  };

  const handleMouseDownOnEvent = e => {
    e.preventDefault();
    e.stopPropagation();
    const eventElem = document.getElementById(`event-${eventThis.id}`);
    const calWrapperElem = document.getElementById("cal-wrapper");
    const calWrapperHeight = calWrapperElem.getBoundingClientRect().height;
    const calWrapperOffT = calWrapperElem.offsetTop;
    const scrHeight = calWrapperElem.scrollHeight;

    const dayWidth = eventElem.offsetWidth;
    const origDayOffsetX = e.nativeEvent.offsetX + 3;

    const originalMouseY = e.pageY;
    let newMouseY = originalMouseY;
    const originalMouseX = e.pageX;
    const origStart = startFloat;
    let startAfterOut = origStart;
    const origStartDate = eventStartObj;
    const origDuration = endFloat - startFloat;
    const currDayDiff = dayDiffToFirstDay;

    const moveEvent = ev => {
      ev.stopPropagation();
      setShowDetailsThis(false);

      const startDateClone = new Date(origStartDate.getTime());

      let startNewPrecise;
      let startNew;
      let startHoursNew;
      let startMinNew;
      let currScrollT;

      if (ev.pageY > calWrapperOffT + calWrapperHeight - 20) {
        currScrollT = calWrapperElem.scrollTop;

        startNew = 23.75 - origDuration;
        startHoursNew = Math.floor(startNew);
        startMinNew = (startNew % startHoursNew) * 60;

        if (currScrollT < scrHeight - calWrapperHeight) {
          calWrapperElem.scrollTop = scrHeight;
          newMouseY = calWrapperOffT + calWrapperHeight - 20;
          startAfterOut = startNew;
        }
      } else if (ev.pageY < calWrapperOffT + 20) {
        currScrollT = calWrapperElem.scrollTop;
        startHoursNew = 0;
        startMinNew = 0;

        if (currScrollT > 0) {
          calWrapperElem.scrollTop = 0;
          newMouseY = calWrapperOffT + 20;
          startAfterOut = 0;
        }
      } else {
        startNewPrecise = startAfterOut + (ev.pageY - newMouseY) / hrHeightPx;

        if (startNewPrecise + origDuration >= 23.875) {
          startNew = 23.75 - origDuration;
          startHoursNew = Math.floor(startNew);
          startMinNew = (startNew % startHoursNew) * 60;
        } else {
          startNew = Math.round(startNewPrecise * 4) / 4;
          startHoursNew = Math.floor(startNew);
          if (startHoursNew === 0) {
            startMinNew = startNew * 60;
          } else {
            startMinNew = (startNew % startHoursNew) * 60;
          }
        }
      }

      const dayDiff = Math.floor(
        (ev.pageX - originalMouseX + origDayOffsetX) / dayWidth
      );

      if (currDayDiff + dayDiff >= 0 && currDayDiff + dayDiff <= 6) {
        startDateClone.setDate(origStartDate.getDate() + dayDiff);
      } else if (currDayDiff + dayDiff < 0) {
        startDateClone.setDate(origStartDate.getDate() - currDayDiff);
      } else {
        startDateClone.setDate(origStartDate.getDate() - currDayDiff + 6);
      }
      if (startNew >= 0) {
        startDateClone.setHours(startHoursNew, startMinNew);
      } else {
        startDateClone.setHours(0, 0);
      }

      setEventThis({
        ...eventThis,
        start_time: startDateClone.getTime(),
      });
    };

    const moveEventStop = eve => {
      eve.stopPropagation();
      setIsDragging(false);
      setShowDetailsThis(true);

      window.removeEventListener("mousemove", moveEvent);
      window.removeEventListener("mouseup", moveEventStop);
    };

    const toggleShowDetails = () => {
      if (!showDetailsThis) {
        setShowDetailsGlobal(true);
      } else {
        setShowDetailsGlobal(false);
      }
      setShowDetailsThis(!showDetailsThis);
      calWrapperElem.scrollTop = Math.floor((startFloat - 2.5) * hrHeightPx);
      window.removeEventListener("mouseup", toggleShowDetails);
    };

    if (isEditing) {
      setIsDragging(true);
      window.addEventListener("mousemove", moveEvent);
      window.addEventListener("mouseup", moveEventStop);
    } else {
      window.addEventListener("mouseup", toggleShowDetails);
      setShowDetailsToggle(!showDetailsToggle);
    }
  };

  const editJob = jobID => {
    setEditLoading(true);
    editJobInstance({
      variables: {
        ...eventThis,
        start_time: eventThis.start_time,
        pay: eventThis.length * payPerHour,
        job: jobID,
      },
    }).then(() => {
      setIsEditing(false);
      setEditLoading(false);
      updateCurrJobs();
    });
  };

  const deleteJob = () => {
    setEditLoading(true);
    cancelJobInstance({ variables: { id: eventThis.id } }).then(() => {
      updateCurrJobs();
      setEditLoading(false);
      setShowDetailsThis(false);
      setIsEditing(false);
    });
  };

  const cancelChanges = () => {
    setEventThis({ ...calEvent });
  };

  const ResizeBar = ({ pos }) => (
    <ResizeBarWrapper
      key={`resize-bar-wrapper-${eventThis.id}-${pos}`}
      onMouseDown={e => handleMouseDownOnResizeBar(e, pos)}
      pos={pos}
    >
      <RemoveRounded tw="text-prim-500" />
    </ResizeBarWrapper>
  );
  ResizeBar.propTypes = { pos: PropTypes.string.isRequired };

  return (
    <>
      <EventWrapper
        startFloat={startFloat}
        endFloat={isOvernight ? 24 : endFloat}
        dayDiffToFirstDay={dayDiffToFirstDay}
        active={showDetailsThis}
        dragging={isDragging}
        editing={isEditing}
        status={status}
        id={`event-${eventThis.id}`}
        onMouseDown={handleMouseDownOnEvent}
        isOvernight={isOvernight}
      >
        {isEditing && <ResizeBar pos="top" />}
        <div key={`event-details-${eventThis.id}`}>
          <div tw="font-semibold truncate" key={`event-title-${eventThis.id}`}>
            {eventThis.job.title}
          </div>
          <div tw="text-sm" key={`event-time-${eventThis.id}`}>
            {`${beautifyTime(eventThis.start_time)} bis ${beautifyTime(
              eventEndObj.getTime()
            )}`}
          </div>
        </div>
        {isEditing && !isOvernight && <ResizeBar pos="bottom" />}
      </EventWrapper>
      {isOvernight && (
        <EventWrapper
          startFloat={0}
          endFloat={endFloat - 24}
          dayDiffToFirstDay={dayDiffToFirstDay + 1}
          active={showDetailsThis}
          dragging={isDragging}
          editing={isEditing}
          status={status}
          id={`event-${eventThis.id}-next-day`}
          onMouseDown={handleMouseDownOnEvent}
          nextDay
        >
          {isEditing && <ResizeBar pos="bottom" />}
        </EventWrapper>
      )}
      <Transition
        in={showDetailsThis}
        timeout={300}
        appear
        mountOnEnter
        unmountOnExit
      >
        {detailsState => (
          <CalenderEventDetails
            state={detailsState}
            status={status}
            detailsData={eventThis}
            firstDay={firstDay}
            setShowDetailsThis={setShowDetailsThis}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            editJob={editJob}
            deleteJob={deleteJob}
            editLoading={editLoading}
            cancelChanges={cancelChanges}
          />
        )}
      </Transition>
    </>
  );
};
CalendarEvent.propTypes = {
  calEvent: PropTypes.shape({
    id: PropTypes.string,
    job: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    status: PropTypes.string,
    department: PropTypes.shape({
      name: PropTypes.string,
    }),
    start_time: PropTypes.number,
    end_time: PropTypes.number,
    length: PropTypes.number,
    pay: PropTypes.number,
    num_req_workers: PropTypes.number,
    workers: PropTypes.arrayOf(
      PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })
    ),
  }).isRequired,
  showDetailsToggle: PropTypes.bool.isRequired,
  setShowDetailsToggle: PropTypes.func.isRequired,
  setShowDetailsGlobal: PropTypes.func.isRequired,
  firstDay: PropTypes.instanceOf(Date).isRequired,
  updateCurrJobs: PropTypes.func.isRequired,
};

export default CalendarEvent;
