import {
  CheckCircleTwoTone,
  EditRounded,
  HighlightOffRounded,
  WarningTwoTone,
} from "@mui/icons-material";
import { addMinutes } from "date-fns";
import React from "react";
import tw, { styled } from "twin.macro";
import { formatDE, hoursToHoursMin } from "../utils/util-functions";

const Wrapper = styled.div(props => [
  tw`flex absolute z-50 w-2/5 transition duration-300`,
  `${props.xKey}: ${props.offX};
    ${props.yKey}: ${props.offY};
    `,
  props.state === "entering" && tw`opacity-100`,
  props.state === "entered" && tw`opacity-100`,
  props.state === "exiting" && tw`opacity-0`,
  props.state === "exited" && tw`opacity-0`,
]);
const Header = tw.div`flex pb-2 text-lg justify-between`;
const WorkerTag = tw.div`px-1 border ml-2`;
const SaveEditBtn = styled.div(({ loading }) => [
  tw`font-semibold text-prim-500 cursor-pointer`,
  loading && tw`text-gray-500 cursor-wait`,
]);
const DeleteEditBtn = styled.div(({ loading }) => [
  tw`text-red-500 cursor-pointer`,
  loading && tw`text-gray-500 cursor-wait`,
]);

const CalenderEventDetails = ({
  state,
  status,
  detailsData,
  firstDay,
  setShowDetailsThis,
  isEditing,
  setIsEditing,
  editJob,
  deleteJob,
  editLoading,
  cancelChanges,
}) => {
  const startStamp = parseInt(detailsData.start_time);
  const startDateObj = new Date(startStamp);
  const eventEndObj = addMinutes(startDateObj, detailsData.length * 60);
  const startFloat = startDateObj.getHours() + startDateObj.getMinutes() / 60;
  let endFloat = startFloat + detailsData.length;
  if (endFloat > 24) endFloat = 24;
  const firstDayMN = firstDay.getTime();
  const startObjClone = new Date(startDateObj.getTime());
  startObjClone.setHours(0, 0, 0, 0);
  const eventMN = startObjClone.getTime();
  const dayDiffToFirstDay = (eventMN - firstDayMN) / 86400000;

  const dateString = `${formatDE(
    startDateObj,
    "eee, dd. MMM ⋅ HH:mm"
  )} bis ${formatDE(eventEndObj, "HH:mm")} (${hoursToHoursMin(
    detailsData.length
  )})`;

  let xKey = "left";
  let offX = `calc(${((dayDiffToFirstDay + 1) * 100) / 7}% + 1rem)`;
  if (dayDiffToFirstDay > 3) {
    xKey = "right";
    offX = `calc(${((7 - dayDiffToFirstDay) * 100) / 7}% + 1rem)`;
  }
  let yKey = "top";
  let offY = `calc(${(startFloat / 25) * 100}% + 2rem - 2%)`;
  if (startFloat <= 1) {
    offY = `calc(${(startFloat / 25) * 100}% + 2rem + 1%)`;
  } else if (startFloat > 14 && endFloat < 23) {
    yKey = "bottom";
    offY = `calc(${((24 - endFloat) / 25) * 100}% - 2%)`;
  } else if (endFloat >= 23) {
    yKey = "bottom";
    offY = `calc(${((24 - endFloat) / 25) * 100}% + 1%)`;
  }

  const handleEditJob = () => {
    editJob(detailsData.job.id);
  };

  return (
    <Wrapper state={state} xKey={xKey} yKey={yKey} offX={offX} offY={offY}>
      <div tw="flex flex-col p-4 bg-white rounded-md shadow-2xl w-full hover:cursor-default space-y-2">
        <Header>
          <div tw="flex flex-col">
            <div tw="text-lg">{detailsData.job.title}</div>
            <div tw="text-sm pt-2">{dateString}</div>
          </div>
          <div tw="flex">
            {!isEditing && (
              <>
                {!detailsData.worker && (
                  <EditRounded
                    tw="mr-1 text-gray-500 hover:(cursor-pointer text-black) transition duration-200"
                    onClick={() => setIsEditing(true)}
                  />
                )}
                <HighlightOffRounded
                  onClick={() => setShowDetailsThis(false)}
                  tw="text-gray-500 hover:(cursor-pointer text-black) transition duration-200"
                />
              </>
            )}
          </div>
        </Header>
        {isEditing ? (
          <>
            <div tw="flex justify-end space-x-4">
              <div
                tw="text-gray-500 cursor-pointer"
                onClick={() => {
                  setIsEditing(false);
                  cancelChanges();
                }}
              >
                abbrechen
              </div>
              <DeleteEditBtn
                loading={editLoading ? true : false}
                onClick={editLoading ? null : deleteJob}
              >
                löschen
              </DeleteEditBtn>
              <SaveEditBtn
                loading={editLoading ? true : false}
                onClick={editLoading ? null : handleEditJob}
              >
                speichern
              </SaveEditBtn>
            </div>
          </>
        ) : (
          <>
            {status === 3 && (
              <div tw="flex flex-col space-y-2 text-sm text-green-500">
                <div tw="flex font-semibold items-end">
                  <CheckCircleTwoTone tw="text-lg mr-2" />
                  voll besetzt
                </div>
                <div tw="flex flex-wrap">
                  {detailsData.workers.map(worker => (
                    <WorkerTag tw="border-green-500">
                      {worker.first_name} {worker.last_name}
                    </WorkerTag>
                  ))}
                </div>
              </div>
            )}
            {status === 2 && (
              <div tw="flex flex-col space-y-2 text-sm text-yellow-500">
                <div tw="flex font-semibold items-end">
                  <WarningTwoTone tw="text-lg mr-2" />
                  {`${detailsData.workers.length}/${detailsData.num_req_workers} besetzt`}
                </div>
                <div tw="flex flex-wrap">
                  {detailsData.workers.map(worker => (
                    <WorkerTag tw="border-yellow-500">
                      {worker.first_name} {worker.last_name}
                    </WorkerTag>
                  ))}
                </div>
              </div>
            )}
            {status === 1 && (
              <div tw="flex flex-col space-y-2 text-sm text-red-500">
                <div tw="flex font-semibold items-end">
                  <WarningTwoTone tw="text-lg mr-2" />
                  {`0/${detailsData.num_req_workers} besetzt`}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default CalenderEventDetails;
