import { useMutation } from "@apollo/client";
import {
  CheckCircleOutlineRounded,
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@mui/icons-material";
import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import PropTypes from "prop-types";

import { CANCEL_JOBINST_MUTATION } from "../utils/mutations";
import { beautifyTime, formatDE } from "../utils/util-functions";
import { JimDialog, LoadingDialog } from "./util-components";

const Wrapper = tw.div`flex my-6 rounded-md text-base`;
const BodyWrapper = styled.div(({ status }) => [
  tw`flex flex-col flex-grow p-2 rounded-md bg-red-300`,
  status === 3 && tw`bg-green-300`,
  status === 2 && tw`bg-yellow-300`,
]);
const WorkerTag = tw.div`px-1 border border-green-500 ml-2`;
const DeleteEditBtn = tw.div`text-red-500 cursor-pointer`;

const CalendarEventMobile = ({ calEvent }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [deleteJobInstance] = useMutation(CANCEL_JOBINST_MUTATION);

  let status = 1;
  if (calEvent.num_req_workers === calEvent.workers.length) {
    status = 3;
  } else if (calEvent.workers.length > 0) {
    status = 2;
  }

  const startDateObj = new Date(parseInt(calEvent.start_time, 10));
  const endDateObj = new Date(
    parseInt(calEvent.start_time, 10) + calEvent.length * 3600000
  );

  const handleClickOnEvent = e => {
    e.stopPropagation();
    setShowDetails(true);
  };

  const getDateString = () => {
    const durHours = Math.floor(calEvent.length);
    let durMin;
    if (durHours === 0) {
      durMin = calEvent.length * 60;
    } else {
      durMin = (calEvent.length % durHours) * 60;
    }
    return `${formatDE(startDateObj, "eee, dd. MMM ⋅ HH:mm")} bis ${formatDE(
      endDateObj,
      "HH:mm"
    )} (${durHours}h${durMin !== 0 ? `${durMin}min` : ""})`;
  };

  const deleteJob = () => {
    setDeleteLoading(true);
    setShowDetails(false);
    deleteJobInstance({ variables: { id: calEvent.id } }).then(() => {
      setDeleteLoading(false);
      setIsEditing(false);
      setShowConfirmDialog(true);
    });
  };

  return (
    <>
      <Wrapper onClick={handleClickOnEvent}>
        <div tw="flex flex-col w-12 pr-4 text-prim-300 justify-center items-end font-semibold text-lg tracking-wider">
          <div>{formatDE(startDateObj, "dd")}</div>
          <div>{formatDE(startDateObj, "LLL").toUpperCase()}</div>
        </div>
        <BodyWrapper status={status}>
          <div tw="font-semibold">{calEvent.job.title}</div>
          {!isEditing && (
            <div>{`${beautifyTime(calEvent.start_time)} bis ${beautifyTime(
              endDateObj.getTime()
            )}`}</div>
          )}
        </BodyWrapper>
      </Wrapper>
      {showDetails && (
        <JimDialog
          open={showDetails}
          handleClose={() => setShowDetails(false)}
          closable
          title={calEvent.job.title}
          tw="flex-col space-y-4"
        >
          <div>{getDateString()}</div>
          <div tw="flex flex-wrap text-sm text-green-500">
            {status === 3 && (
              <div tw="flex flex-col space-y-2 text-sm text-green-500">
                <div tw="flex font-semibold items-end">
                  <CheckCircleTwoTone tw="text-lg mr-2" />
                  voll besetzt
                </div>
                <div tw="flex flex-wrap">
                  {calEvent.workers.map(worker => (
                    <WorkerTag tw="border-green-500">
                      {worker.first_name} {worker.last_name}
                    </WorkerTag>
                  ))}
                </div>
              </div>
            )}
            {status === 2 && (
              <div tw="flex flex-col space-y-2 text-sm text-yellow-500">
                <div tw="flex font-semibold items-end">
                  <WarningTwoTone tw="text-lg mr-2" />
                  {`${calEvent.workers.length}/${calEvent.num_req_workers} besetzt`}
                </div>
                <div tw="flex flex-wrap">
                  {calEvent.workers.map(worker => (
                    <WorkerTag tw="border-yellow-500">
                      {worker.first_name} {worker.last_name}
                    </WorkerTag>
                  ))}
                </div>
              </div>
            )}
            {status === 1 && (
              <div tw="flex flex-col space-y-2 text-sm text-red-500">
                <div tw="flex font-semibold items-end">
                  <WarningTwoTone tw="text-lg mr-2" />
                  {`0/${calEvent.num_req_workers} besetzt`}
                </div>
              </div>
            )}
          </div>
          <div tw="flex justify-end space-x-4">
            <DeleteEditBtn onClick={deleteJob}>löschen</DeleteEditBtn>
          </div>
        </JimDialog>
      )}
      <JimDialog
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        tw="flex flex-col justify-center items-center space-y-4 w-56"
      >
        <div tw="flex text-2xl tracking-wide">Job gelöscht!</div>
        <CheckCircleOutlineRounded tw="flex text-6xl text-red-600" />
      </JimDialog>
      <LoadingDialog open={deleteLoading} />
    </>
  );
};
CalendarEventMobile.propTypes = {
  calEvent: PropTypes.shape({
    id: PropTypes.string,
    job: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    status: PropTypes.string,
    department: PropTypes.shape({
      name: PropTypes.string,
    }),
    start_time: PropTypes.number,
    end_time: PropTypes.number,
    length: PropTypes.number,
    pay: PropTypes.number,
    num_req_workers: PropTypes.number,
    workers: PropTypes.arrayOf(
      PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default CalendarEventMobile;
